#main-slider .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

#main-slider .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

#main-slider .slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to bottom, #313f47 0%, rgba(49, 63, 71, 0) 100%);
  z-index: 1;
}

#main-slider .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 80%;
  z-index: 2;
}

#main-slider .overlay h2,
#main-slider .overlay h1,
#main-slider .overlay p {
  color: #fff;
}

#main-slider .overlay h2 {
  font-size: 44px;
  margin: 0;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 10px;
}

#main-slider .overlay h1 {
  font-size: 64px;
  margin: 10px 0;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 10px;
}

#main-slider .overlay p {
  font-size: 20px;
  margin: 20px 0;
}

#main-slider .site-button.btn-half span {
  font-size: 16px;
  font-weight: 600;
}

#main-slider .nav-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #313f47;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

#main-slider .prev-slide:before,
#main-slider .next-slide:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
}

#main-slider .prev-slide:before {
  border-width: 10px 15px 10px 0;
  border-color: transparent white transparent transparent;
  margin-left: -1px;
}

#main-slider .next-slide:before {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent white;
  margin-left: 2px;
}

#main-slider .prev-slide {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

#main-slider .next-slide {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

#main-slider .scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
}

#main-slider .scroll-down-indicator .scroll-down {
  display: inline-block;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 1200px) {
  #main-slider .overlay h2 {
    font-size: 36px;
  }
  #main-slider .overlay h1 {
    font-size: 48px;
  }
  #main-slider .overlay p {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  #main-slider .overlay h2 {
    font-size: 28px;
  }
  #main-slider .overlay h1 {
    font-size: 36px;
  }
  #main-slider .overlay p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  #main-slider .overlay h2 {
    font-size: 24px;
  }
  #main-slider .overlay h1 {
    font-size: 32px;
  }
  #main-slider .overlay p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  #main-slider .overlay h2 {
    font-size: 20px;
  }
  #main-slider .overlay h1 {
    font-size: 28px;
  }
  #main-slider .overlay p {
    display: none;
  }
}

#main-slider .site-button.btn-half {
  background-color: white;
  color: #313f47;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

#main-slider .site-button.btn-half:hover {
  background-color: #313f47;
  color: white;
}
